(function($) {
    function groupLetter() {
        anchorLetter();
        
        var allClass = $('.page-number');
        for(let i=0;i<allClass.length;i++) {
          var $group = ".group-" + allClass[i].text;
          var $listClass = "list-" + allClass[i].text;
          $($group).wrapAll( "<ul class='news-events__list " + $listClass  + "'/>");
        }
        
        anchorGroup();
        
    }
    
    function anchorLetter() {
        var allClass = $('.hazards__list-anchor');
        for(let i=0;i<allClass.length;i++) {
            var anchorLetter = "list-" + $(allClass[i]).text().trim();
            $(allClass[i]).addClass(anchorLetter);
        }
    }
    
    function anchorGroup() {
        var allClass = $('.page-number');
        for(let i=0;i<allClass.length;i++) {
            var $anchorClass= ".list-"+ allClass[i].text;
            $($anchorClass).wrapAll("<div class='hazards__list'/>");
        }
    }
    
    function hazardItem() {
        var pageNumners = $('.page-number');
        for(let i=0;i<pageNumners.length;i++) {
            var $anchorLink = 
            '<div class="hazards__item">' + 
                "<a href='#letter_" + pageNumners[i].text +"'" + "name=letter_" + pageNumners[i].text  +  " class='hazards__link'>"  + pageNumners[i].text + "</a>"
            '</div>'
            
            $('.hazards__item-container').append($anchorLink);
        }
    }
    
    groupLetter();
    hazardItem();
})(jQuery);

(function() {
    const $anchors = $('.hazards__list-anchor');

    $(document).on('click', '.hazards__link' , function() {
      if ( $('a[name=' + $.attr(this, 'name') + ']').offset() == undefined ) {
        return;
      } else {
        $('html, body').animate({
          scrollTop:  $('h3 a[name=' + $.attr(this, 'name') + ']').offset().top - 150
        }, 500);
      }
      return false;
    });
})(jQuery);


