(function ($) {
    'use strict';

    //funnelback autocomplete
    var autocomplete = function () {

        var fbServer = 'https://showcase.funnelback.com/s/suggest.json';
        var autoSuggestPath = fbServer + '?collection=funnelback-community-search&fmt=json++&show=6&sort=0&profile=_defaul' +
                't&partial_query=';
        var partialQuery = '';
    }

}(jQuery));