(function($){
    'use strict';

    var $backToTop = $('.back-top'),
    	scrollPositionPrev = 0;
    
	$(document).scroll(function() {
		var scrollPosition = $(document).scrollTop(),
			footerPosition = $('.footer-wrapper').offset().top - $(window).height();
		if(scrollPosition > 100) {//Not at the start
			/*if(scrollPosition < footerPosition) {//Not at the end*/
				if(!$backToTop.hasClass('fixed')) {
					if(scrollPositionPrev > scrollPosition) {
						$backToTop.addClass('fixed');
					} else {
						$backToTop.hide().addClass('fixed').fadeIn(300, function() {
							$backToTop.removeAttr('style');
						});						
					}
				}		
			/*} else { //At the end
				$backToTop.removeClass('fixed');
			}*/	
		} else {//At the start
			if($backToTop.hasClass('fixed')) {//Coming back
				$backToTop.css('position', 'fixed').removeClass('fixed').fadeOut(300, function() {
					$backToTop.removeAttr('style');
				});		
			}				
		}

		scrollPositionPrev = scrollPosition;
	});

    $backToTop.click(function() {
		$('html, body').animate({
			scrollTop : 0
		}, 300);
	});
}(jQuery));