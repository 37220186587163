(function($) {
  const value = $('.breadcrumbs__item--active').text();

  const activeMenu = function( value ) {
    switch ( value ) {
      case 'Workshops':
        $('.perform-menu_workshop').addClass('current');
        break;
      case 'Resources':
        $('.perform-menu_resources').addClass('current');
        break;
      default:
        $('.perform-menu_home').addClass('current');
    }
  }

  activeMenu( value );
})(jQuery);