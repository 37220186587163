
$(function() {

  const copyLinkToClipboard = function() {

    $(document).on('click', '.js-copy-link', function(e) {
      if (e.preventDefault) { 
        e.preventDefault(); 
      } else { 
        e.returnValue = false; 
      }
      //e.preventDefault();
      e.preventDefault();
      const el = document.createElement('textarea');
      el.value = $(this).attr('href');
      el.setAttribute('readonly', '');
      el.style.position = 'fixed';
      el.style.left = '-9999px';

      document
        .body
        .appendChild(el);
      el.select();
      document.execCommand('copy');
      document
        .body
        .removeChild(el);

      var linkElem = $(this);
      var tagContent = $(this).html();
      linkElem.html(function(id, tagContent) {
        if ($(window).width() > 600) {
          return tagContent.replace(/Copy link/, 'Copied!');
        } else  {
          $(this).addClass('mb-display');
          return tagContent.replace(/Copy link/, 'Copied!');       
        }
        
      });

      // check if ie browser clik copy and scroll to element;
      // if (navigator.userAgent.match(/msie/i) || navigator.userAgent.match(/trident/i) ){
      //   console.log('ie');
      //   $('html,body').animate({
      //     scrollTop: $(this).offset().top - 500
      //   }, 'fast');
      // }

      setTimeout(function() {
        linkElem
          .html(function(id, tagContent) {
            if( tagContent.indexOf('clipboard') > -1) {
              return tagContent.replace(/Copied!/, 'Copy link');
            } else {
              $(this).removeClass('mb-display');
              return tagContent.replace(/Copied!/, 'Copy link');
            }
            
          });

      }, 1500);

    });

  }

  copyLinkToClipboard();

  var DEBUG = 0;
  if ($('.blog__more-button').length) {
    $(document).on('click', '.blog__more-button' , function(e) {
    var $blogButton = $('.blog__more-button'),
        $blogList = $blogButton.siblings('.blog__list');
      if (!$blogButton.hasClass('disabled')) {
        $blogButton.addClass('disabled');
        $.ajax($blogButton.attr('href'))
          .done(function(page) {
            if (DEBUG) {
              console.log(page);
            }
            var $page = $(page),
              $newLists = $page.find('.blog__list'),
              $newLink = $page.find('.blog__more-button');

            if ($newLists.length) {
              $blogList.append($newLists.html());
            }

            if ($newLink.length) {
              $blogButton.attr('href', $newLink.attr('href')).removeClass('disabled');
            } else {
              $blogButton.remove();
            }

          })
          .fail(function(jqXHR, textStatus) {
            if (DEBUG) {
              console.log('Blogs fail: ' + textStatus);
              $blogButton.removeClass('disabled');
            } else {
              window.location.href = $blogButton.attr('url');
            }
          });
      }

      return false;
    }); 
  }
  
});