/**
 * @file Tabs
 * 
 * @author mars xu <mxu@squiz.pl>
 * @date July 2018
 */

const consultationTab = (function() {

  const $tabItems       = $('.consultation__tab-title'),
        $tabContents    = $('.accordion__item-content'),
        $tabLink        = $('.consultation__tab-btn'),
        activetabsClass = ('tabs__item--active'),
        activeContentClass = ('tabs__content--active');

  // Toggles active tab and content
  function toggleActiveTab(clickedLink) {
    var $activeTabItem = $(clickedLink).parent();
    var $activeContent = $(clickedLink.hash);
    $tabContents.removeClass(activeContentClass);
    $tabItems.removeClass(activetabsClass)
    $activeTabItem.addClass(activetabsClass);
    $activeContent.addClass(activeContentClass);
  }

  // Binds all the events
  function bind() {
    $tabLink.on('click', function(e) {
      e.preventDefault();
      toggleActiveTab(this);
    });
  }

  // Toggle first tab on
  function activateFirstTab() {
    $('.consultation__tab-btn:eq(0)').click();
  }

  /**
   * Initialisation
  */
  return {
    init: function() {
      bind();
      activateFirstTab();
    }
  }
})();

(function() {
    consultationTab.init();
}(jQuery));

/*
 --------------------
 Consultations  detail js function
 --------------------
 */
(function($) {
  'use strict';
  const ajaxForm = function() {
    var newsPageCheckbox = function() {
      var newCheckbox = '<div class="form__ele form__ele--small"></div>';
      $('.news__results-filters__list input[type="checkbox"]').each(function() {
        var $this = $(this),
          $thisLabel = $this.next();

        // move checkbox inside label
        $this.prependTo($thisLabel);

        // add new checkbox
        $thisLabel.append($(newCheckbox));
      });
    };
    var _doAjax = function(newUrl, $thisTarget, varObj) {
      varObj.$ajaxOverlay.velocity('fadeIn');

      $.ajax({
        url: newUrl,
        method: 'GET'
      }).done(function(html) {
        var newHtmlTarget = $(html).find('.ajax-target'),
          articleCount = $(html).find('.article-count'),
          newsPagination = $(html).find('.news__pagination'),
          newMobileFilters = $(html).find('.news__results-filters__list');


        $thisTarget.html(newHtmlTarget);
        $('.article-count').html(articleCount);
        $('.news__pagination').html(newsPagination);
        $('.news__results-filters__list').html(newMobileFilters);
        newsPageCheckbox();
        varObj.$ajaxOverlay.velocity('fadeOut');

        var pushUrl = newUrl.replace('SQ_DESIGN_NAME=blank&', '');
        window.history.pushState(null, null, pushUrl);

        if ($('.ajax-scroll-top').length) {
          $(window).scrollTop(0);
        }
      });
    };

    var _reconstructUrl = function($thisTarget) {
      // it needs to be input field with name and value populated
      var $thisFilters = $thisTarget.find('input[type="checkbox"]');
      var newUrl = '';

      $thisFilters.each(function() {
        var $this = $(this);

        if ($this.is(':checked')) {
          newUrl += '&' + $this.attr('name') + '=' + $this.val();
        }
      });

      return newUrl;
    };

    // js functions for next step and loading progress
    var activeBtn = function() {
      $(document).on('click', '.ajax-form-container input[type="submit"][value="Submit"]', function() {
        $('.spinner').removeClass('hidden');
        setTimeout(function(){
         $('.spinner').addClass('hidden');
       }, 1000);
      });

      $(document).on('click', '.ajax-form-container input[type="submit"][value="Next Page"]', function() {
        $('html, body').animate({
          scrollTop: $(".ajax-online-form").offset().top - $('.header-main').height()
        }, 1000);
      });
    };

    var triggerAjax = function(varObj) {

      $('body').on('change', 'input[type=radio].radio--publish', function() {
        if ($(this).val() === '0') {
          $('.target-error').show();
        } else {
          $('.target-error').hide();
        }
      });
      //varObj.$ajaxBtn.on('click', function(e) {
      $('body').on('click', '.ajax-box input[type="checkbox"], .ajax-btn a', function(e) {
        var $this = $(this),
          $thisBox = $('.ajax-box'),
          $thisTarget = $('.ajax-target');
        //var thisTarget = $thisTarget.attr('data-target');

        if ($this.is('a')) {
          e.preventDefault();
          var newUrl = $this.attr('href');
        } else {
          var newUrl = '//' + location.host + location.pathname + '?SQ_DESIGN_NAME=blank' + _reconstructUrl($thisBox);
        }
        _doAjax(newUrl, $thisTarget, varObj);
      });

      function changeInputs() {
        $('.sq-form-question input[type="checkbox"], .sq-form-question input[type="radio"], .sq-form-section input[type="checkbox"], .sq-form-section input[type="radio"]').each(function() {
          var $this = $(this);
          if (!$this.hasClass('form__input')) {
            $('<div class="form__ele"></div>').insertAfter($this);
            $this.addClass('form__input')
            $this.parent().addClass('form__' + $this.attr('type'));
            $this.siblings('label').prepend($this, $this.siblings('.form__ele'));
          }
        });
      };

      // display online form
      varObj.$ajaxFormBtn.on('click', function(e) {
        e.preventDefault();

        var $this = $(this);
        var newUrl = $this.attr('data-form-url');

        if ($this.hasClass('is-opened')) {
          $('.ajax-form-container').velocity('slideUp');
          $this.removeClass('is-opened');
          $this.html('Use our online form');
        } else {
          $('.ajax-form-container').velocity('slideUp');
          $('.loader').velocity('fadeIn');

          // check if IE9
          if ($('.lt-ie10').length) {
            //if ($('.lt-ie10').length || !$('.lt-ie10').length) {

            var onlineFormHtml = '<iframe class="no-responsive-iframe" src="' + newUrl + '" id="online-form-ie9" name="online-form-ie9" width="100%" height="600" frameborder="0"></iframe>';

            $('.ajax-form-container').html(onlineFormHtml);
            changeInputs();
            $('.ajax-form-container').velocity('slideDown', {
              progress: function() {
                $('.loader').hide();
              }
            });

            $this.addClass('is-opened');
            $this.html('Close the online form');
          } else {
            $.ajax({
              url: newUrl,
              method: 'GET'
            }).done(function(html) {
                
              // consultation submit form
              var onlineFormHtml = $(html);

              $('.ajax-form-container').html(onlineFormHtml);
              // validateForm();
              changeInputs();
              $('.ajax-form-container').velocity('slideDown', {
                progress: function() {
                  $('.loader').hide();
                }
              });

              $this.addClass('is-opened');
              $this.html('Close the online form');
            });
          }
        }
      });

      // display subscription form
      if ($('.subscription-form').length > 0) {

        var $this = $('.subscription-form');
        var newUrl = $this.attr('data-form-url');

        // check if IE9
        if ($('.lt-ie10').length) {

          var onlineFormHtml = '<iframe class="no-responsive-iframe" src="' + newUrl + '" id="online-form-ie9" name="online-form-ie9" width="100%" height="600" frameborder="0"></iframe>';

          $('.ajax-form-container').html(onlineFormHtml);
          $('.ajax-form-container').velocity('slideDown', {
            progress: function() {
              $('.loader').hide();
            }
          });
        } else {
          $.ajax({
            url: newUrl,
            method: 'GET'
          }).done(function(html) {
            var onlineFormHtml = $(html).find('#mainBody');

            $('.ajax-form-container').html(onlineFormHtml);
            $('.ajax-form-container').velocity('slideDown', {
              progress: function() {
                $('.loader').hide();
              }
            });
          });
        }
      };

      // submit online form
      $(document).on('click', '.ajax-form-container form input[type="submit"]', function(e) {
        e.preventDefault();



        var $submitBtn = $(this),
          $thisForm = $submitBtn.closest('form');
        var newUrl = $thisForm.attr('action'),
          isFile = false;

        var radioSelectedIndex = $thisForm.find('input.radio--publish:checked').val();

        $thisForm.find('input').each(function() {
          var $input = $(this);

          if ($input.attr('type') == 'file') {
            isFile = true;
          }
        });

        if (isFile) {
          var formData = new FormData();
          formData.append($submitBtn.attr('name'), $submitBtn.attr('value'));



          $thisForm.find('input,textarea').each(function () {
            var $input = $(this);
            var inputType = $input.attr('type');
            var inputName = $input.attr('name');
            var inputVal = $input.val();

            if (inputType === 'file') {
              //var image = $input.files[0];
              var image = $input.prop('files')[0];
              formData.append(inputName, image);
            } else if (!inputType) {
              formData.append(inputName, inputVal);
            } else if (inputType === 'radio' || inputType === 'checkbox') {
              if ($input.prop('checked')) {
                formData.append(inputName, inputVal);
              }
            } else {
              if (
                $submitBtn.val() === 'Previous Page' &&
                (inputVal === 'Submit' || inputVal === 'Next Page')
              ) {
                // do nothing
              } else {
                formData.append(inputName, inputVal);
              }
            }
          });

        } else {
          var formData = $thisForm.serialize();
          formData += '&' + $submitBtn.attr('name') + '=' + $submitBtn.attr('value');
        }
        // if($thisForm.valid() || $submitBtn.attr('id') === 'form_email_83549_previous_page'){
        $('.ajax-form-container').velocity('slideUp', {
          complete: function() {
            $('.loader').velocity('fadeIn');
          }
        });

        if (isFile) {

          $.ajax({
            url: newUrl,
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false
          }).done(function(html) {
            var onlineFormHtml = $(html);

            $('.loader').hide();

            $('.ajax-form-container').html(onlineFormHtml);
            // validateForm();
            changeInputs();
            $('.ajax-form-container').velocity('slideDown', {
              complete: function() {
                $(window).scrollTop($(window).scrollTop() + 1);
              }
            });
          });
        } else {
          $.ajax({
            url: newUrl,
            method: 'POST',
            data: formData
          }).done(function(html) {
            var onlineFormHtml = $(html);

            $('.loader').hide();

            $('.ajax-form-container').html(onlineFormHtml);
            // validateForm();
            changeInputs();
            $('.ajax-form-container').velocity('slideDown', {
              complete: function() {
                $(window).scrollTop($(window).scrollTop() + 1);
              }
            });

            // check correct radio button in returned form
            $thisForm = $('.ajax-form-container form');
            $thisForm.find('li.form__radio').eq(radioSelectedIndex).find('input.radio--publish').prop('checked', true);
          });
        }
      });

      // submit again link on the thank you page
      $('body').on('click', '.submit-again-link', function(e) {
        e.preventDefault();

        var $this = $(this);

        if ($this.text() == 'Submit again') {

          var newUrl = $this.attr('href');

          $('.ajax-form-container').velocity('slideUp', {
            complete: function() {
              $('.loader').velocity('fadeIn');
            }
          });

          $.ajax({
            url: newUrl,
            method: 'GET',
          }).done(function(html) {
            var onlineFormHtml = $(html);

            $('.loader').hide();

            $('.ajax-form-container').html(onlineFormHtml);
            changeInputs();
            $('.ajax-form-container').velocity('slideDown');
          });
        }
      });
    };

    function init() {
      var varObj = {
        $ajaxBtn: $('.ajax-box input[type="checkbox"], .ajax-btn a'),
        $ajaxOverlay: $('.ajax-overlay'),
        $ajaxFormBtn: $('.ajax-online-form'),
        $ajaxFormContainer: $('.ajax-form-container')
      };

      triggerAjax(varObj);
      activeBtn();
    }

    init();
  };

  const consultations = function() {
    var mqListener = function(varObj) {
      $(window).on('breakpoint-change', function(e, breakpoint) {
        varObj.breakpoint = breakpoint;

        // add is-no-active for submission accordion on large mq
        if (varObj.breakpoint == 'mq-large') {
          varObj.$submissions.addClass('is-no-active');
        } else {
          varObj.$submissions.removeClass('is-no-active');
        }
      });
    };

    var dateMoment = function(varObj) {
      varObj.$consultationGraph.each(function() {
        var $this = $(this);

        if ($this.hasClass('consultations__graph--landing')) {
          var $dateRemaining = $this.prev().find('.consultation__date-remaining'),
            $dateTotal = $this.prev().find('.consultation__date-total'),
            $progressBar = $this.find('.progressbar');
        } else {
          var $dateRemaining = $this.find('.consultation__date-remaining'),
            $dateTotal = $this.find('.consultation__date-total'),
            $progressBar = $this.find('.progressbar');
        }

        var dateStartArray = moment($this.attr('data-start'), 'YYYY, MM, DD, hh, mm, ss'),
          dateEndArray = moment($this.attr('data-end'), 'YYYY, MM, DD, hh, mm, ss'),
          dateToday = moment(),
          dateRemaining,
          //ceil to show one day remaining on the last day
          dateTotal = Math.ceil(dateEndArray.diff(dateStartArray, 'days', true)),
          progressCount = 0,
          progressCountData;

        var $currentDateRemaining = $('.current-consultations__remaining-time .consultation__date-remaining');
        // progress 0 if didn't start yet; progress 100 if already finished
        if (dateEndArray.diff(dateToday, 'days') < dateTotal) {
          //calculate exact progress using floating value of days insted of rounded up
          progressCount = Math.abs(dateStartArray.diff(dateToday, 'days', true) / dateEndArray.diff(dateStartArray, 'days', true) * 100) > 100 ? 100 : Math.abs(dateStartArray.diff(dateToday, 'days', true) / dateEndArray.diff(dateStartArray, 'days', true) * 100);
          dateRemaining = dateEndArray.diff(dateToday, 'days')
        } else {
          dateRemaining = Math.ceil(dateEndArray.diff(dateStartArray, 'days', true));
        }

        progressCountData = progressCount / 100;
        dateRemaining = dateRemaining > 0 ? dateRemaining : 0;
        $('span.progressbar').attr('data-progress', progressCountData).css('width', progressCount + '%');
        // $progressBar.html(progressCount + '%').attr('data-progress', progressCountData);
        $dateRemaining.html(dateRemaining);

        $dateRemaining.text() > 1 ? $currentDateRemaining.html($dateRemaining.text() + " Days Remaining") : $currentDateRemaining.html($dateRemaining.text() + " Day Remaining");

        $dateTotal.html(dateTotal);
      });
    };

    function init() {
      var varObj = {
        $submissions: $('.submissions'),
        $consultationGraph: $('.consultations__graph'),
        breakpoint: ''
      };

      mqListener(varObj);
      dateMoment(varObj);
    }

    init();
  };

  const init = function() {
    ajaxForm();
    consultations();
  }

  init();
})(jQuery);
