(function ($) {
	'use strict';

	//** Autocomplete */
	// Query completion with summary text.
	$('.js-typeahead-query-long').qc({
		datasets: {
			organic: {
				collection: 'safework-rebuild',
				profile: 'autocomplete',
				program: 'https://nswdfsi-search.squiz.cloud/s/suggest.json',
				show: '5',
				template: {

					suggestion: function (context) {
						var itemResult = '<a href="%URL%" class="search-suggest-result-large__container"><p class="search-suggest-result-large__title">%TITLE%</p><p class="search-suggest-result-large__summary">%SUMMARY%</p></a>';

						itemResult = itemResult.replace('%URL%', String(context.label.displayUrl));
						itemResult = itemResult.replace('%TITLE%', String(context.label.title));

						// Check for the preferred "search" metadata description.
						if (context.label.metaData.desc !== undefined && context.label.metaData.desc !== '') {

							itemResult = itemResult.replace('%SUMMARY%', String(context.label.metaData.desc));
						} else {

							// Try for the alternate search description.
							if (context.label.metaData.desc2 !== undefined && context.label.metaData.desc2 !== '') {

								itemResult = itemResult.replace('%SUMMARY%', String(context.label.metaData.desc2));
							} else {

								// Fallback to empty summary.
								itemResult = itemResult.replace('%SUMMARY%', '');
							}
						}

						return itemResult;
					}
				}
			}
		},
		length: 3,
		typeahead: {
			events: {
				select: function (event, suggestion) {
					// override select action in order to not submit form on selection.
					// Redirect the user to the suggested page.
					window.location.href = suggestion.label.displayUrl;
				}
			}
		},
		interactionLog: ''
	});

	// Standard query completion.
	$('.js-typeahead-query-standard').qc({
		datasets: {
			organic: {
				collection: 'safework-rebuild',
				profile: '',
				program: 'https://nswdfsi-search.squiz.cloud/s/suggest.json',
				show: '5',
				template: {

					suggestion: function (context) {
						var itemResult = '<div class="search-suggest-result-standard__container">%LABEL%</div>';

						itemResult = itemResult.replace('%LABEL%', String(context.label));

						return itemResult;
					}
				}
			}
		},
		length: 3,
		interactionLog: ''
	});
  
  // Resources library query completion.
	$('.js-typeahead-query-resources').qc({
		datasets: {
			organic: {
				collection: 'safework-resources-library',
				profile: '',
				program: 'https://nswdfsi-search.squiz.cloud/s/suggest.json',
				show: '5',
				template: {

					suggestion: function (context) {
						var itemResult = '<div class="search-suggest-result-standard__container">%LABEL%</div>';

						itemResult = itemResult.replace('%LABEL%', context.label.title);

						return itemResult;
					}
				}
			}
		},
		length: 3,
		interactionLog: ''
	});

}(jQuery));