/**
 * @file Footer links columns toggle
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date April 2018
 */

var footerLinksColToggle = (function () {
    var colToggle = $('.footer-col__toggle');

    // Toggles quick search form 
    function toggleAriaExpanded() {
        var $toggledEl = $(event.target);
        if (!$toggledEl.hasClass('footer-col__toggle')) {
            $toggledEl = $toggledEl.parent();
        }
        var ariaExpanded = $toggledEl.attr('aria-expanded');
        var expanded;
        switch (ariaExpanded) {
            case "true": {
                expanded = true;
                break;
            }
            case "false": {
                expanded = false;
                break;
            }
            default: {
                break;
            }
        }
        
        $toggledEl.attr('aria-expanded', !expanded);
    }

    // Binds all the events
    function bind() {
        colToggle.click(function() {
            toggleAriaExpanded();
        });
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
        }
    }
})();

(function() {
    footerLinksColToggle.init();
}(jQuery));