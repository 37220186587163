(function($){
  'use strict';
  $(window).resize(function() {
    if($(window).width() > 1200) {
      $('iframe.home-mg__link').each(function (index) {
        $(this).attr('src', $(this).attr('src'));
        return false;
      });
    }
  })
}(jQuery));