(function($){
    'use strict';

    if ($('.js-risk-predictor').length) {
        var $riskPredictor = $('.js-risk-predictor'),
            riskPredictorUrl = $riskPredictor.attr('data-url'),

            $riskPredictorSubmit = $riskPredictor.find('.js-risk-predictor-submit'),
            $riskPredictorIndustry = $riskPredictor.find('.js-risk-predictor-industry'),
            $riskPredictorOccupation = $riskPredictor.find('.js-risk-predictor-occupation'),

            $riskPredictorContainer = $riskPredictor.find('.js-risk-predictor-container'),

    		$riskPredictorDefaultContent = $('.js-radar-default-content'),
    		$riskPredictorTitle = $riskPredictorContainer.find('h2 span'),
    		$riskPredictorList = $riskPredictorContainer.find('.js-risk-predictor-list'),
    		$riskPredictorItemTemplate = $riskPredictorList.children().first().clone(),

            $riskPredictorIndustryAlert = $('.js-risk-predictor-alert'),
            $riskPredictorIndustryInfo = $('.js-risk-predictor-related');

        var $riskPredictorIndustryAlertType1 = $('.risk-predictor__alert'),
            $riskPredictorIndustryAlertType2 = $('.risk-predictor__tip'),
            $riskPredictorIndustryAlertType3 = $('.risk-predictor__infos');

        var agencyText = $('.js-risk-predictor-info').text();

        $riskPredictorSubmit.click(function() {
            if($riskPredictorIndustry.val().length && $riskPredictorOccupation.val().length) {
                var riskPredictorFullUrl = riskPredictorUrl + '?industry=' + encodeURIComponent($riskPredictorIndustry.val()) + '&occupation=' + encodeURIComponent($riskPredictorOccupation.val());

	    		$riskPredictorContainer.hide();
	    		$riskPredictorIndustryInfo.hide();
				$riskPredictorIndustryAlert.hide();
                $riskPredictorDefaultContent.hide();

                $.ajax({
                    url: riskPredictorFullUrl,
                    dataType: "json"})
                .done(function(data) {
                    if (data.length === 0 ) { //No results
                        $riskPredictorContainer.hide();
                        $riskPredictorDefaultContent.show();
                    } else {// Has results
                        if(data.industryAlert) {
                            $riskPredictorIndustryAlert.html( data.industryAlert.content);
                            if(data.industryAlert.type == "information") {
                                $riskPredictorIndustryAlertType2.show();
                            } else if (data.industryAlert.type == "alert") {
                                $riskPredictorIndustryAlertType1.show();
                            } else if (data.industryAlert.type == "warning") {
                                $riskPredictorIndustryAlertType3.show();
                            }
                        }

                        if(data.industryInfo) {
                            $riskPredictorIndustryInfo.css('background-image', 'url(' + data.industryInfo.image + ')');
                            $riskPredictorIndustryInfo.children().html(data.industryInfo.content);

                            if((data.industryInfo.buttonOneLabel != "" && data.industryInfo.buttonOneURL != "") || (data.industryInfo.buttonTwoLabel != "" && data.industryInfo.buttonTwoURL != "")) {
                                var links = null;
                                var highlighted = "highlighted";
                                if(data.industryInfo.buttonOneLabel != "" && data.industryInfo.buttonOneURL != "") {
                                    links = $("<div/>", {"class": "section__links"})
                                    .append($("<a/>", {"href": data.industryInfo.buttonOneURL, "class": highlighted, text: data.industryInfo.buttonOneLabel}));
                                    highlighted = "";
                                }

                                if(data.industryInfo.buttonTwoLabel != "" && data.industryInfo.buttonTwoURL != "") {
                                    if(links != null) {
                                        links
                                        .append($("<a/>", {"href": data.industryInfo.buttonTwoURL, "class": highlighted, text: data.industryInfo.buttonTwoLabel}));
                                    } else {
                                        links = $("<div/>", {"class": "section__links"})
                                        .append($("<a/>", {"href": data.industryInfo.buttonTwoURL, "class": highlighted, text: data.industryInfo.buttonTwoLabel}));
                                    }
                                }

                                $riskPredictorIndustryInfo.children().append(links);
                            }

                            $riskPredictorIndustryInfo.fadeIn(300);
                        }

                        $riskPredictorList.empty();

                        $.each(data.results, function(index, item) {
                            var $item = createItem($riskPredictorItemTemplate.clone(), item, agencyText);
                            $riskPredictorList.append($item);
                        });
                        $riskPredictorTitle.text($riskPredictorIndustry.find('option:selected').text() + ', ' + $riskPredictorOccupation.find('option:selected').text());
                        $riskPredictorContainer.fadeIn(300);
                    }
                });
            }
        });
    }


    function createItem($template, data, agencyText) {
        var $itemTitle = $template.find('h3'),
            $itemInfo = $template.find('.js-risk-predictor-info'),
            $itemPeriod = $template.find('.js-risk-predictor-period'),
            $itemFatalities = $template.find('.js-risk-predictor-fatalities'),
            $itemInjuries = $template.find('.js-risk-predictor-injuries'),
            $itemHours = $template.find('.js-risk-predictor-hours'),
            $itemClaims = $template.find('.js-risk-predictor-claims'),
            $itemLink = $template.find('a');

        var iconClass = makeSafeForCSS(data[$itemTitle.attr('data-source')]);
        $template.addClass(iconClass);

        $itemTitle.text(data[$itemTitle.attr('data-source')]);
        $itemInfo.text(agencyText + data[$itemInfo.attr('data-source')]);
        $itemPeriod.text(data[$itemPeriod.attr('data-source')]);
        $itemLink.attr('href', data[$itemLink.attr('data-source')]);

        if(data[$itemFatalities.attr('data-source')] !== null && data[$itemFatalities.attr('data-source')] !== 0) {
            $itemFatalities.text(data[$itemFatalities.attr('data-source')]);
        } else {
            $itemFatalities.parents('li').remove();
        }

        if(data[$itemInjuries.attr('data-source')] !== null && data[$itemInjuries.attr('data-source')] !== 0) {
            $itemInjuries.text(data[$itemInjuries.attr('data-source')]);
        } else {
            $itemInjuries.parents('li').remove();
        }

        if(data[$itemHours.attr('data-source')] !== null && data[$itemHours.attr('data-source')] !== 0) {
            $itemHours.text(data[$itemHours.attr('data-source')]);
        } else {
            $itemHours.parents('li').remove();
        }

        // if(data[$itemClaims.attr('data-source')] !== null && data[$itemClaims.attr('data-source')] !== 0) {
        //  $itemClaims.text(data[$itemClaims.attr('data-source')]);
        // } else {
        //  $itemClaims.parents('li').remove();
        // }

        return $template;
    }

    //convert title to css class
    function makeSafeForCSS(name) {
        name = name.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '');
        return name.replace(/[^a-z0-9]/g, function(s) {
            var c = s.charCodeAt(0);
            if (c == 32) return '-';
            if (c >= 65 && c <= 90) return s.toLowerCase();

            return c.toString(16).slice(0, 0);
        });
    }

}(jQuery));