(function($){
    'use strict';
    $('.home-slider__slides').slick({
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: 0,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    infinite: true,
                    centerMode: false,
                    variableWidth: false
                }
            },
        ],
    });
}(jQuery));