/**
 * @file Quick search toggle
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date March 2018
 */

var quickSearch = (function () {
    var $quickSearchToggle = $('.quick-search__toggle');
    // Toggles quick search form 
    function toggleAriaExpanded() {
        var ariaExpanded = $quickSearchToggle.attr('aria-expanded');
        var expanded;
        switch (ariaExpanded) {
        case 'true': {
            expanded = true;
            break;
        }
        case 'false': {
            expanded = false;
            break;
        }
        default: {
            break;
        }
        }
        
        $quickSearchToggle.attr('aria-expanded', !expanded);
    }

    // Binds all the events
    function bind() {
        $quickSearchToggle.click(function() {
            toggleAriaExpanded();
            $('.quick-search__input').focus();
        });
    }

    /**
     * Initialisation
     */
    return {
        init: function() {
            bind();
        }
    };
}());

(function() {
    quickSearch.init();
}(jQuery));