/**
 * @file Table of Contents attachment
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date May 2018
 */

$(function () {
    if ($('body').hasClass('long-page')) {
        tableOfContents.parseHTML('article');
        tableOfContents.attachTOC('#main-content', 'before', 'div', 'toc-inner', 'Table of contents');
        tableOfContents.attachTOC('.long-page .content-wrapper', 'after', 'sidebar', 'toc-sidebar, sticky', 'Contents');

        $(".toc-sidebar li.toc-lvl2__item > a, .toc-sidebar li.toc-lvl3__item > a, .toc-sidebar li.toc-lvl4__item > a, .toc-sidebar li.toc-lvl5__item > a").each(function () {
            $(this).append('<svg class="svg-icon icon-arrow-right"><use xlink:href="./?a=338241:static/mysource_files/sprite.svg#arrow-right"></use></svg>');
        });

        $("li.toc-lvl2__item > a, li.toc-lvl3__item > a, li.toc-lvl4__item > a, li.toc-lvl5__item > a").each(function () {
            $(this).click(function () {
                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top - 135
                }, 500);
            });
        });

    }
});