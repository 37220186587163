/**
 * @file    Table of contents.
 *
 * @author  Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date    May 2018.
 */

var tableOfContents = (function() {
    var tocJSON = [];
    var tocHTML = '';

    /**
     * Builds html string of Table of Contents children levels
     * 
     * @param {Object} el ToC single level
     * @param {Number} startLevel counter for class names purposes
     * @returns String HTML of all children elements
     */
    function _printStructure(el,startLevel) {
        var html = '';
        var level = startLevel;
        function printLevel(el,level) {
            if (el.children.length > 0) {
                html += '<ul class="toc-lvl'+level+'__list">';
                el.children.map(function(el) {
                    html += '<li class="toc-lvl'+level+'__item"><a href="#toc-'+el.index+'">'+el.headerText+'</a>';
                    if (el.children.length > 0) {
                        var deeperLevel = level + 1;
                        printLevel(el,deeperLevel);
                    }
                    html += '</li>';
                });
                html += '</ul>';
            }
        }
        printLevel(el,level);
        return html;
    }

    /**
     * Prepares HTML of all Table of Contents
     * 
     * @param {any} wrapper 
     * @param {any} moduleName 
     * @returns 
     */
    function _printHTML() {
        var htmlToPrint = '';
        if (tocJSON.length > 0) {
            htmlToPrint += '<ul class="toc-lvl1__list">';
            tocJSON.map(function(el) {
                htmlToPrint += '<li class="toc-lvl1__item"><a href="#toc-'+el.index+'">'+el.headerText+'</a>';
                if (el.children.length > 0) {
                    htmlToPrint += _printStructure(el,2);
                }
                htmlToPrint += '</li>';
            });
            htmlToPrint += '</ul>';
        }

        return htmlToPrint;
    }

    /**
     * Parse HTML
     * 
     * Parses HTML, adds id attributes to elements and translates headers to JSON structure
     * @param {string} longDocElement element selector  
    */
    var parseHTML = function(longDocElement) {
        var article = document.querySelector(longDocElement);

        var lastPushedH1 = -1;
        var lastPushedH2 = -1;
        var lastPushedH3 = -1;
        var lastPushedH4 = -1;
        var lastPushedH5 = -1;

        article.querySelectorAll('h1,h2,h3,h4,h5').forEach(function(el, index) {
            var tagName = el.tagName;
            var headerText = el.innerText;
            var mapObj = {
                tagName: tagName,
                headerText: headerText,
                index: index,
                children: []
            };


            switch (tagName) {
                case 'H1': 
                    tocJSON.push(mapObj);
                    lastPushedH1 = lastPushedH1 + 1;
                    lastPushedH2 = -1;
                    lastPushedH3 = -1;
                    lastPushedH4 = -1;
                    lastPushedH5 = -1;
                    el.setAttribute('id', 'toc-'+index);
                    break;
                case 'H2':
                    tocJSON[lastPushedH1].children.push(mapObj);
                    lastPushedH2 = lastPushedH2 + 1;
                    lastPushedH3 = -1;
                    lastPushedH4 = -1;
                    lastPushedH5 = -1;
                    el.setAttribute('id', 'toc-'+index);
                    break;
                case 'H3':
                    tocJSON[lastPushedH1].children[lastPushedH2].children.push(mapObj);
                    lastPushedH3 = lastPushedH3 + 1;
                    lastPushedH4 = -1;
                    lastPushedH5 = -1;
                    el.setAttribute('id', 'toc-'+index);
                    break;
                case 'H4':
                    tocJSON[lastPushedH1].children[lastPushedH2].children[
                    lastPushedH3
                    ].children.push(mapObj);
                    lastPushedH4 = lastPushedH4 + 1;
                    lastPushedH5 = -1;
                    el.setAttribute('id', 'toc-'+index);
                    break;
                case 'H5':
                    tocJSON[lastPushedH1].children[lastPushedH2].children[
                    lastPushedH3
                    ].children[lastPushedH4].children.push(mapObj);
                    lastPushedH5 = lastPushedH5 + 1;
                    el.setAttribute('id', 'toc-'+index);
                    break;
                default:
                    break;

            }
        });

        tocHTML = _printHTML();
    };

    /**
     * Attaches TOC to selected element in very specific way
     * 
     * @param {String} where target reference element
     * @param {String} position insert before or append element 
     * @param {String} element wrapper element to be created
     * @param {String} classes comma separated list of classes
     * @param {String} headerText header text
     */
    function attachTOC(where, position, element, classes, headerText) {
        var targetElement = document.querySelector(where);
        var attachedElement = document.createElement(element);

        var headerHTML = headerText.length > 0 ? '<h1 class="toc-header">'+headerText+'</h1>' : '';

        classes.split(',').map(function(single) {
            attachedElement.classList.add(single.trim());
        });
        attachedElement.innerHTML = headerHTML + tocHTML;

        if (position === 'before') {
            targetElement.insertBefore(attachedElement, targetElement.firstChild);
        } else {
            targetElement.appendChild(attachedElement);
        }
    }

    /**
     * Outputs ToC to console.log as JSON
     * 
     */
    function getJSON() {
        return tocJSON;
    }

    return {
        parseHTML: parseHTML,
        attachTOC: attachTOC,
        getJSON: getJSON
    };
})();