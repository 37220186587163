/**
 * @file Tabs
 *
 * @author mars xu <mxu@squiz.pl>
 * @date Aug 2018
 */

const ResourcesLibrary = (function() {

  const $checkBoxItem = $('.reousrces-filter input.js-filter-option'),
        $ajaxSearch   = $('.js-submit'),
        URL  = window.location.href;

  var   $selectValue   = $('.search__resources__select').attr('data-value');


  // trigger form submit after select sort option
  function selectTrigger() {
    $('.search__resources__select').on('change', function() {
      $selectValue = $('.search__resources__select').val();
      $('.js-submit').trigger('click');
    });
  }

  // choose sort by and submit form
  function selectedSubmit() {
      const $topicInputs = $('.topic__checkboxs input'),
            $audienceInputs = $('.audience__checkboxs input'),
            $typeInputs = $('.type__checkboxs input'),
            $industryInputs = $('.industry__checkboxs input');

      if( $selectValue == "") {
        $('.search__resources__select option[value=null]').attr('selected','selected');
      } else {
        $('.search__resources__select option[value=' +  $selectValue + ']').attr('selected','selected');
      }
      for(let i=0; i< $topicInputs.length; i++) {
        checkedOption($($topicInputs[i]).val());
      }

      for(let i=0; i< $audienceInputs.length; i++) {
        checkedOption($($audienceInputs[i]).val());
      }

      for(let i=0; i< $industryInputs.length; i++) {
        checkedOption($($industryInputs[i]).val());
      }

      for(let i=0; i< $typeInputs.length; i++) {
        checkedOption($($typeInputs[i]).val());
      }
  }

  // tick choeck box and submit form
  function checkedSubmit() {
      const $checkbox = $('input.js-filter-option');
       $checkbox.each( function() {
         $(this).on('click', function() {
          $('.js-submit').trigger('click');
         });
       });
  }

  function checkedOption( value ) {
    const valueDecode = value.replace(/\s+/g, '+');
    const valueTrim = value.replace(/\s+|&/g, '');


    if (URL.indexOf(valueDecode) > -1 ) {
      $('#' + valueTrim).prop('checked', true);
    } else {
      $('#' + valueTrim).prop('checked', false);
    }
  }

  return {
    init: function() {
      selectedSubmit();
      checkedSubmit();
      selectTrigger();
    }
  };
})(jQuery);

(function($) {
    ResourcesLibrary.init();
})(jQuery);
