var squizSearchPage = (function () {
    // Populate the search summary in the page banner.
    function updateBanner() {
        $('.js-search-results-banner').text($('.js-search-results-summary').text());
    }

    function activateMoreResults() {
        var DEBUG = 0;

        if($('.search__more-button').length) {
            var $searchButton = $('.search__more-button'),
                $searchList = $searchButton.siblings('.search__list');

            $searchButton.click(function() {
                if(!$searchButton.hasClass('disabled')) {
                    $searchButton.addClass('disabled');
                    $.ajax($searchButton.attr('href'))
                        .done(function(page) {
                            if(DEBUG) {
                                console.log(page);
                            }

                            var $page = $(page),
                                $newList = $page.find('.search__list'),
                                $newLink = $page.find('.search__more-button');
                                
                            if($newList.length) {
                                $searchList.append($newList.html());
                            }

                            if($newLink.length) {
                                $searchButton.attr('href', $newLink.attr('href')).removeClass('disabled');
                            } else {
                                $searchButton.remove();
                            }
                            
                        })
                        .fail(function(jqXHR, textStatus) {
                            if(DEBUG) {
                                console.log('Search fail: ' + textStatus);
                                $searchButton.removeClass('disabled');
                            } else {
                                window.location.href = $searchButton.attr('url');
                            }
                        });
                }

                return false;
            });
        }
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            updateBanner();
            activateMoreResults();
        }
    }
})();

(function() {
    squizSearchPage.init();
}(jQuery));