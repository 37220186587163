(function($) {
  // Tabs
  // Cache DOM
  var $tabLink = $('.c-tab__link');
  var $tabSection = $('.c-tab__section');

  // Event bidning
  $tabLink.on('click', handleClick);

  function handleClick(e) {
    e.preventDefault();

    var $thisLink = $(this);
    var $tabTarget = $($thisLink.attr('href'));

    //check for class that makes clicking link not jump to #id element
    if ($thisLink.hasClass('c-tab__link-no-anchor')) {
      $tabTarget = $($thisLink.attr('href').replace('-no-anchor', ''));
    }

    $tabLink.removeClass('is-tab-active').attr('aria-selected', false);
    $tabSection.removeClass('is-tab-active').attr('aria-hidden', true);

    $thisLink.addClass('is-tab-active').attr('aria-selected', true);
    $tabTarget.addClass('is-tab-active').attr('aria-hidden', false);
  }
})(jQuery);
