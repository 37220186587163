/**
 * @file Tabs
 * 
 * @author mars xu <mxu@squiz.pl>
 * @date Aug 2018
 */


const detailGuide = (function($) {

  const $currentPosition = $('#current-page').attr('data-position'),
        currentClass     = ('current');


  function markPosition() {
    $('.guide-links__item[ data-position="' + $currentPosition + '"]').addClass(currentClass);
  }


  function addLinkText() {
    const $prevText = $('.current').prev().find('a').text(),
          $nextText = $('.current').next().find('a').text();

    const $prevLink = $('.previous-page .page-links__text'),
          $nextLink = $('.next-page .page-links__text');

    $prevLink.text($prevText);
    $nextLink.text($nextText);
  }
  /**
   * Initialisation
  */
  return {
    init: function() {
      markPosition();
      addLinkText();
    }
  }
})(jQuery);

(function($) {
  detailGuide.init();
})(jQuery);
