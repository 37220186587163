/**
 * @file Sidebar navigation
 *
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date April 2018
 */

var sidebarNavigation = (function () {
    var $lvl2Link = $('.side-nav__l2-link');
    var lvl2ActiveClass = 'side-nav__l2-item--active';

    // Toggles quick search form
    function toggleL2(clickedLink) {
        var $toggledItem = $(clickedLink).parent();
        $toggledItem.toggleClass(lvl2ActiveClass);
    }

    // Binds all the events
    function bind() {
        $lvl2Link.click(function(e) {
            e.preventDefault();
            toggleL2(this);
        });
    }
    
    function initLoad() {
        $('.side-nav__l4-item--active').closest('li.side-nav__l3-item').addClass('side-nav__l3-item--active');
        $('.side-nav__l3-item--active').closest('li.side-nav__l2-item').addClass('side-nav__l2-item--active');
    }
    /**
     * Initialisation
     */
    return {
         init: function() {
            initLoad();
            bind();
        }
    }
})();

(function() {
    sidebarNavigation.init();

    var $sideNavToggle = $('.side-nav__toggle');

    if($sideNavToggle.length) {
        var $sideNavButton = $sideNavToggle.find('button'),
            $sideNav = $sideNavToggle.siblings('ul');

        $sideNavButton.click(function() {
            if($sideNavButton.hasClass('active')) {
                $sideNavButton.removeClass('active');
                $sideNav.slideUp(300, function() {
                    $sideNav.removeClass('active').removeAttr('style');
                });
            } else {
                $sideNavButton.addClass('active');
                $sideNav.slideDown(300, function() {
                    $sideNav.addClass('active').removeAttr('style');
                });
            }

            return false;
        });
    }
}(jQuery));