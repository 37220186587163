/**
 * @file Tabs
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date April 2018
 */

var tabs = (function () {
    var $tabItems = $('.tabs__item');
    var $tabContents = $('.tabs__content');
    var $tabLink = $('.tabs__item-link');
    var activetabsClass = ('tabs__item--active');
    var activeContentClass = ('tabs__content--active');

    // Toggles active tab and content
    function toggleActiveTab(clickedLink) {
        var $activeTabItem = $(clickedLink).parent();
        var $activeContent = $(clickedLink.hash);
        $tabContents.removeClass(activeContentClass);
        $tabItems.removeClass(activetabsClass)
        $activeTabItem.addClass(activetabsClass);
        $activeContent.addClass(activeContentClass);
    }

    // Toggle first tab on
    function activateFirstTab() {
        $('.tabs__item-link:eq(0)').click();
    }

    // Binds all the events
    function bind() {
        $tabLink.click(function(e) {
            e.preventDefault();
            toggleActiveTab(this);
        })
    }

    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
            activateFirstTab();
        }
    }
})();

(function() {
    tabs.init();
}(jQuery));