/**
 * @file Accordions
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date April 2018
 */

var accordions = (function () {
    var $accordionHeaders = $('.accordion__item-name');
    var $accordionLink = $('.accordion__item-link');
    var activeAccordionClass = 'accordion__item--active';
    var $arrow  = $('.accordion__item-name .icon-arrow-right');

    // Toggles active accordion class name
    function toggleAccordionPanel(clickedLink) {
        var $accordionHeader = $(clickedLink).parent();
        var active = $accordionHeader.hasClass(activeAccordionClass);
        $accordionHeader.removeClass(activeAccordionClass);
        if (active === false) {
            $accordionHeader.addClass(activeAccordionClass);
        }
    }

    // Binds all the events
    function bind() {
        $accordionLink.click(function(e) {
            e.preventDefault();
            toggleAccordionPanel(this);
        })
    }
    
    function activeArrow() {
        $arrow.on('click', function(e) {
            e.preventDefault();
            toggleAccordionPanel(this.closest('a.accordion__item-link'));
        });
    }
    /**
     * Initialisation
     */
    return {
         init: function() {
            bind();
            // activeArrow();
        }
    }
})();

(function() {
    accordions.init();
}(jQuery));