/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
$('body').removeClass('no-js').addClass('js-enabled');


/*
--------------------
Modules
--------------------
*/

/*-- IE NodeList forEach polyfill --*/
(function () {
    if ( typeof NodeList.prototype.forEach === "function" ) return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
})();


 /*--- Random string generator ---*/
 function randString(n) {
    if(!n) {
        n = 5;
    }
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for(var i=0; i < n; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }


/*-- Position sticky polyfill --*/
$(function() {
    var elements = document.querySelectorAll('.sticky');
    Stickyfill.add(elements);
});

//SVG Polyfill init
if(window.svg4everybody) {
    window.svg4everybody();
}

/*
 --------------------
 Extra js function for social media on home page
 --------------------
 */
(function() {
  
  // filter ajax functions
  const Listing = (function() {
      
    const $result_num = $('.result');
    
    const init_num = function() {
      const $result_element = $('.lists-result');
      if($result_element == null || $result_element == undefined) return;
      
      let $result_value = $result_element.text();
      $result_value == "1" ? $result_num.text($result_value + ' Result') : $result_num.text($result_value + ' Results');
    };
    
    const submitFormViaAJAX = function(theForm) {

      const $result = $('.lists-result'),
            $result_num = $('.result'),
            $thisForm = $(theForm),
            $thisSubmit = $thisForm.find('input[type="submit"]');

      if ($result == null || $result == undefined) return;
      if ($result_num == null || $result_num == undefined) return;

      //apply filter form and return results
      $thisForm.on('submit', function(e) {
        e.preventDefault();
          const jqxhr = $.get($thisForm.attr('action'), $thisForm.serialize(), function(data) {

        }).done(function(data) {

          var secionName = 'news-events';
          if (!$(data).find('.' + secionName).length) {
          	secionName = 'blog';
          }

          const $results = $(data).find('.' + secionName).html(),
                $noResult = $(data).find('.no-result'),
                $result_value = $(data).find('.lists-result').text().trim();
            
          if ($result_value == '0') {
            $result_num.text('No Results');
          } else {
            $result_value == "1" ? $result_num.text($result_value + ' Result') : $result_num.text($result_value + ' Results');
          }
          
          if($noResult.html() != undefined) {
            $('.content--news-events').html($('<p></p>').html($noResult.html()));  
          } else {
              $('.content--news-events').html($('<div class="' + secionName + '"></div>').html($results));
          }
        }).fail(function() {
          alert('There was an error submitting your form. Please reload the page and try again.');
        }).always(function() {
          console.log("finished");
        });
        return false;
      });
      return true;
    };

    return {
      applyFilter: submitFormViaAJAX,
      initNum: init_num
    }
  })();
  
  // replace faceebook item descrption link with a tag
  const Fb = (function() {
      const $fb_decs = $('.home-mg__desc');
      
      const applyLink = function() {
        $fb_decs.each(function() {
          $(this).html($(this).text().split(" ").map(function(str) {
            return str.trim().replace(/^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?[\r\n]*/g, '<a target="_blank" href="$&">$&</a>');
          }).join(" "));
        });
      };
      
      return {
          applyLink: applyLink
      }
  })();
  
  // swap home page social mdia wediget items
  const SocialMedia = (function() {
    
    // global swap function
    $.fn.swap = function (elem) {
        elem = elem.jquery ? elem : $(elem);
        return this.each(function () {
         $(document.createTextNode('')).insertBefore(this).before(elem.before(this)).remove();
        });
    };
    
    const swapItems_first = function() {
        const $second_item   = $($('.home-mg__item')[1]),
              $third_item    = $($('.home-mg__item')[2]),
              $forth_item    = $($('.home-mg__item')[3]),
              $sevnth_item   = $($('.home-mg__item')[6]),
              $nith_item     = $($('.home-mg__item')[8]);
              
    
        const $first_info_item = $($('.home-mg__item--article')[0]),
              $second_info_item = $($('.home-mg__item--article')[1]).removeClass('home-mg__item--promoted'),
              $first_fb_item = $($('.home-mg__item--social-facebook')[0]),
              $first_youtube = $($('.home-mg__item--video')[0]),
              $first_twitter = $($('.home-mg__item--social-twitter')[0]);
              
        $second_item.swap($first_fb_item);
        $third_item.swap($first_youtube);
        $forth_item.swap($first_info_item);
        $sevnth_item.swap($first_twitter);
        $nith_item.swap($second_info_item);
    };
    
    const swapItems_second = function() {
        
        const $second_item_b = $($('.home-mg__item')[11]),
              $third_item_b = $($('.home-mg__item')[12]),
              $forth_item_b = $($('.home-mg__item')[13]),
              $sevnth_item_b = $($('.home-mg__item')[16]),
              $nith_item_b    = $($('.home-mg__item')[18]);
              
        const $second_youtube = $($('.home-mg__item--video')[1]),
              $third_info_item = $($('.home-mg__item--article')[2]),
              $forth_info_item = $($('.home-mg__item--article')[3]).removeClass('home-mg__item--promoted'),
              $second_twitter = $($('.home-mg__item--social-twitter')[1]),
              $second_fb_item = $($('.home-mg__item--social-facebook')[1]);
  
        $second_item_b.swap($second_fb_item);
        $third_item_b.swap($second_youtube);
        $forth_item_b.swap($third_info_item);
        $sevnth_item_b.swap($second_twitter ); 
        $nith_item_b.swap($forth_info_item);
    }
    
    const applyWrapper = function() {
        const items = $('.home-mg__item');
        for(var i = 0; i < items.length; i+=10) {
            items.slice(i, i+10).wrapAll("<ul class='home-mg__list'></ul>");
        }
    }
    
    const displayList = function() {
        const $scocndList = $($('.home-mg__list')[1]),
              $thirdList  = $($('.home-mg__list')[2]),
              $moreBtn    = $('.home-mg__button-more.js-btn');
              
        $scocndList.addClass('hidden');
        $thirdList.addClass('hidden');
        
        $moreBtn.on('click', function() {
            $scocndList.hasClass('hidden') ? $scocndList.removeClass('hidden') : $thirdList.removeClass('hidden');
            $thirdList.hasClass('hidden') ? $moreBtn.removeClass('hidden') : $moreBtn.addClass('hidden');
        });
    }
    
    const finalSwap = function() {
        const $scocndList = $($('.home-mg__list')[1]),
              $thirdList  = $($('.home-mg__list')[2]);
        
        const $fb_second  = $($scocndList.find('.home-mg__item--social-facebook')),
              $twitter_scond = $($scocndList.find('.home-mg__item--social-twitter')),
              $sevnth_item  = $($scocndList.find('.home-mg__item')[6]);
              
        const $first_item = $($thirdList.find('.home-mg__item')[1]),
              $fb_third   = $($thirdList.find('.home-mg__item--social-facebook')),
              $twitter_third = $($thirdList.find('.home-mg__item--social-twitter')),
              $info_first     = $($thirdList.find('.home-mg__item--article')[0]),
              $info_last     = $($thirdList.find('.home-mg__item--article')[1]),
              $youtube    = $($thirdList.find('.home-mg__item--video'));
              
              
        $fb_second.swap($twitter_scond);  
        $sevnth_item.swap($twitter_scond);
        
        $fb_third.swap($first_item);
        $youtube.swap($($thirdList.find('.home-mg__item')[2]));
        $twitter_third.swap($($thirdList.find('.home-mg__item')[6]));
        $info_last.swap($($thirdList.find('.home-mg__item')[3]));
        $info_first.removeClass('home-mg__item--promoted');
    }
    
    const swapBlog = function() {
      const $firstUl = $('.home-mg__list')[0],
      $secondUl = $('.home-mg__list')[1];

      const $blogItem = $($firstUl).find('.home-mg__item--blog')[1],
      $newsItem = $($secondUl).find('.home-mg__item--news')[0];

      $($blogItem).swap($($newsItem));
    }

    const sortNews = function() {
      var content = [];
      var list = $('.home-mg__item--news:not(.home-mg__item--blog)');
      for(var i=0; i<list.length; i++) {
        content.push($('.home-mg__item--news:not(.home-mg__item--blog)[index="' + i + '"]').html());
      }

      for(var i=0; i<list.length; i++) {
        $($('.home-mg__item--news:not(.home-mg__item--blog)')[i]).html(content[i]);
      }
    }

    return {
       swapFirst: swapItems_first,
       swapSecond: swapItems_second,
       applyWrapper: applyWrapper,
       displayList: displayList,
       finalSwap: finalSwap,
       swapBlog: swapBlog,
       sortNews: sortNews
    }
  })();
 
  
  /* =============== init modules ============= */
  const iniPostMPAjax = function() {
    Listing.initNum();  
    $('form.filters__form:not(.resources)').each(function() {
        Listing.applyFilter(this);
    });
  };
  
  const initFB = function() {
      Fb.applyLink();
  }
  
  const initSocial = function() {
      SocialMedia.swapFirst();
      SocialMedia.swapSecond();
      SocialMedia.applyWrapper();
      SocialMedia.displayList();
      SocialMedia.finalSwap();
      SocialMedia.swapBlog();
      SocialMedia.sortNews();
  }
  

  /* =============== init ============= */
  iniPostMPAjax ();
  initFB();
  initSocial();
//   initFinalSwap();
})();