(function($){
    'use strict';
    $(function() {
        var $filtersSearchHeader = $('.filters--foldable .filters__heading');
        var filtersSearchHeaderClassActive = 'filters__heading--active';

        $filtersSearchHeader.click(function() {
            $(this).toggleClass(filtersSearchHeaderClassActive);
        });
    });

    $('.js-datepicker').datepicker({
        //dateFormat: "dd-mm-yy"
        dateFormat: "yy-mm-dd"
    });
}(jQuery));

(function($) {
    const $clearBtn = $('.filter__clear-btn'),
          $pefrormForm = $('.perform--content form'),
          $listingForm = $('.filters__form');

      const restForm = function( $form ) {
        $clearBtn.on('click', function(e) {
            e.preventDefault();

            $form.find('input[type="text"]').val('');

            $form.find("select").each(function() {
                $(this).prop('selectedIndex',0);
            });

            $form.find('input[type="checkbox"]').each(function() {
                $(this).prop( "checked", false );
            });
        });
    };

    $clearBtn.on('click', function(e) {
        e.preventDefault();
        $('.init').html('All').attr('data-value','');
        $('.filters__select-item').removeClass('selected');
        $('.filters__select-list').addClass('hidden');
    });

    restForm( $pefrormForm );
    restForm( $listingForm );

    // $clearBtn.on('click', function(e) {
    //     e.preventDefault();
    //     $listingForm.find('input[type="text"]').val('');

    //     $listingForm.find("select").each(function() {
    //         $(this).prop('selectedIndex',0);
    //     });

    //     $listingForm.find('input[type="checkbox"]').each(function() {
    //         $(this).prop( "checked", false );
    //     });

    //     $('.init').html('All').attr('data-value','');
    //     $('.filters__select-item').removeClass('selected');
    //     $('.filters__select-list').addClass('hidden');
    // });
})(jQuery);

// ul li dropdown js function
(function($) {
    const Dropdown = (function() {
        const $audienceList = $('.audience-select'),
              $typeList     = $('.type-select'),
              $topicList     = $('.topic-select'),
              $industryList     = $('.industry-select'),
              $dropdownlist = $('.filters__select-list');


        function toggle() {
            $('span.init').on('click', function() {
                if ($(this).next(".filters__select-list").hasClass('hidden')) {
                    $(".filters__select-list").addClass('hidden');
                    $(this).next(".filters__select-list").removeClass('hidden');
                } else  {
                    $(this).next(".filters__select-list").addClass('hidden');
                }
            });

            $(document).on("click", function(e) {
                if ($(e.target).is('span.init') === false) {
                    $(".filters__select-list").addClass("hidden");
              }
            });

            $('.risk-predictor__select-arrow').on('click', function() {
                $(this).next('.init').trigger('click');
                return false;
            });
        }

        function selectedItem( $list ) {
            const $dropdownlist = $list.find('.filters__select'),
                  $allOptions = $dropdownlist.find('.filters__select-list').children(),

                  selecName   = $list.attr('name');

            $dropdownlist.on('click', 'li:not(.init)', function() {
                $allOptions.removeClass('selected');
                $(this).closest('.filters__select-list').addClass('hidden');
                $(this).addClass('selected');
                $dropdownlist.children('.init').html($(this).html());
                $dropdownlist.children('.init').attr('data-value', $(this).attr('data-value'));
                $('#' +selecName).val( $(this).attr('data-value') );

            });
        }

        function loadedSelectedValue() {
            const $audienceValue = $('#audience-select').val(),
                  $typeValue     = $('#type-select').val(),
                  $topicValue    = $('#topic-select').val(),
                  $industryValue    = $('#industry-select').val();

            _applyValue( $audienceList, $audienceValue);
            _applyValue( $typeList, $typeValue);
            _applyValue( $topicList, $topicValue);
            _applyValue( $industryList, $industryValue);
        }


        function _applyValue( $audienceList, value ) {
            if( value == '' || value == undefined) {
                $audienceList.find('.init').html( 'All' ).attr( 'data-value', '') ;
            } else {
                $audienceList.find('.init').html( value ).attr( 'data-value', value );
                $('.filters__select-item[data-value="' + value + '"' +  ']').addClass('selected');
            }
        }

        return {
            init: function() {
                loadedSelectedValue();
                toggle();
                selectedItem( $audienceList );
                selectedItem( $typeList );
                selectedItem( $topicList );
                selectedItem( $industryList );

                //risk radar
                selectedItem( $('.risk-industry__select'));
                selectedItem( $('.risk-occupation__select'));
            }
        }
    })();


    /*
     *******
     Init
     *******
    */
    Dropdown.init();
})(jQuery);

