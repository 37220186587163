(function($) {
  // Info panel
  // Cache DOM
  var $infoPanel = $('.p-info-panel__link');
  var $closeInfoContentBtn = $('.js-close-info-content');
  var $disputeContents = $('.dispute-tiles__contents');

  // Moves tile contents to outside of the main wrappers so they can slide in properly
  $('#page-wrapper').append($disputeContents);

  // Bind events
  $infoPanel.on('click', toggleInfoPanel);
  $closeInfoContentBtn.on('click', closeInfoContent);

  function initInfoPanel() {
    if (window.location.hash) {
      var hash = window.location.hash.substring(1);
      var $infoContent = getTarget('#' + hash);

      $infoContent.addClass('is-active');
      
      if ($('body').find('.p-info-panel').length !== 0) {
        $('body').addClass('is-infopanel-active');
      }
  
      $('body').on('click', onBodyClick);
    }
  };

  function getTarget(source) {
    return $(source);
  }

  function onBodyClick(e) {
    if (e.target === this) {
      closeInfoContent.call($('.js-close-info-content'));
    }
  }

  function toggleInfoPanel(e) {
    e.preventDefault();

    var $this = $(this);
    var $infoContent = getTarget($this.attr('href'));

    $infoContent.addClass('is-active');
    $('body').addClass('is-infopanel-active');

    $('body').on('click', onBodyClick);

    // add hash to url
    window.location.hash += $this.attr('href');
  };

  function closeInfoContent() {
    var $this = $(this);
    var $infoContent = $this.closest('.p-info-section');

    $infoContent.removeClass('is-active');
    $('body').removeClass('is-infopanel-active');
    $('body').off('click', onBodyClick);

    // remove hash from url
    history.pushState('', document.title, window.location.pathname);
  }

  initInfoPanel();
})(jQuery);
