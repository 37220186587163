/**
 * @file Header links toggle
 * 
 * @author Konrad Kaliszewski <kkaliszewski@squiz.pl>
 * @date March 2018
 */

var headerLinks = (function () {
    var $headerLinksToggle = $('.header-links__toggle');
    // Toggles quick search form 
    function toggleAriaExpanded() {
        var ariaExpanded = $headerLinksToggle.attr('aria-expanded');
        var expanded;
        switch (ariaExpanded) {
            case 'true': {
                expanded = true;
                break;
            }
            case 'false': {
                expanded = false;
                break;
            }
            default: {
                break;
            }
        }
        
        $headerLinksToggle.attr('aria-expanded', !expanded);
    }

    function stickyHeader() {
        var $header = $('.header-main');
        
        $(document).scroll(function() {
            var scrollPosition = $(document).scrollTop();

            if(scrollPosition > 300) {//Not at the start
                if(!$header.hasClass('fixed') && !$header.hasClass('animating')) {
                    $header.addClass('animating').addClass('fixed').slideDown(300, function() {
                        $header.removeClass('animating');

                        if ($('body').hasClass('long-page')) {
                            $('.toc-sidebar').addClass('toc-sidebar-fixed-header');
                        }

                    });  
                    
                }   
            } else if (scrollPosition == 0) {//At the very start
                $header.stop().removeClass('animating').removeClass('fixed').removeAttr('style');
                
                if ($('body').hasClass('long-page')) {
                    $('.toc-sidebar').removeClass('toc-sidebar-fixed-header');
                }

            } else {//Coming back
                if($header.hasClass('fixed') && !$header.hasClass('animating')) {//Coming back
                    $header.addClass('animating').slideUp(300, function() {
                        $header.removeClass('animating').removeClass('fixed').removeAttr('style');
                    });     
                }               
            }
        });
    }

    // Binds all the events
    function bind() {
        stickyHeader();

        $headerLinksToggle.click(function() {
            toggleAriaExpanded();
        });
    }

    /**
     * Initialisation
     */
    return {
        init: function() {
            bind();
        }
    };
}());

(function() {
    headerLinks.init();
}(jQuery));